<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vs-popup classContent="popup-example" title="Ajouter une Banque" :active.sync="popupBanque">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input class="w-full" type="text" label="Nom de la banque" name="NomBanque" v-model="NomBanque" />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input class="w-full" type="text" label="Sigle" name="NomAbrege" v-model="NomAbrege" />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="creatBanque" :disabled="!validateForm">Enregistrer</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="reset_data" status = "false" popupBanqueUpdate = "false" >Annuler</vs-button>
          </div>
        </div>
        <!-- </vx-card> -->
      </vs-popup>
      <vs-popup classContent="popup-example" title="Modifier la Banque" :active.sync="popupBanqueUpdate">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input class="w-full" type="text" label="Nom de la banque" name="NomBanque" v-model="NomBanque" />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input class="w-full" type="text" label="Sigle" name="NomAbrege" v-model="NomAbrege" />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="updateBanque" :disabled="!validateForm">Modifier</vs-button>
          </div>
        </div>

        </vs-popup>
      <vx-card>
        <vs-table stripe pagination max-items="50" search :data="BanqueData">
          <template slot="header">
            <!-- ACTION - DROPDOWN -->
            <vs-dropdown vs-trigger-click class="cursor-pointer">

              <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                <span class="mr-2 leading-none">Actions</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <vs-dropdown-menu>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>Supprimer</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>CSV</span>
                  </span>
                </vs-dropdown-item>

              </vs-dropdown-menu>
            </vs-dropdown>
            <vs-button class="mb-4 ml-5 md:mb-0" @click="popupBanque=true">Nouvelle banque</vs-button>
          </template>
          <template slot="thead">
            <vs-th>
              N°
            </vs-th>
            <vs-th sort-key="NomBanque">
              Nom commercial
            </vs-th>
            <vs-th sort-key="NomAbrege">
              Sigle
            </vs-th>
            <vs-th >
              Actions
            </vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td :data="data[indextr]">
                {{indextr + 1}}
              </vs-td>

              <vs-td :data="data[indextr].NomBanque">
                {{data[indextr].NomBanque}}
              </vs-td>

              <vs-td :data="data[indextr].NomAbrege">
                {{data[indextr].NomAbrege}}
              </vs-td>

              <vs-td :data="data[indextr]._id">
                <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                  <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateBanqueShowForm(tr)" />
                  <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr)" />
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  data () {
    return {
      idBanque: null,
      NomAbrege: null,
      NomBanque: null,
      popupBanque : false,
      popupBanqueUpdate: false
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.NomBanque !== null
    },

    BanqueData () {
      return this.$store.state.banque.banques
    }
  },
  methods: {
    creatBanque () {
      const payload = {
        NomAbrege: this.NomAbrege,
        NomBanque: this.NomBanque
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('banque/addBanque', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          //this.popupUser = false
          this.popupBanque = false
          this.$vs.loading.close()
          this.reset_data()
        })
        .catch(err => {
          console.error(err)
        })
    },
    reset_data () {
      this.NomAbrege = null
      this.NomBanque = null
    },
    updateBanqueShowForm (data) {
      this.NomAbrege = data.NomAbrege
      this.NomBanque = data.NomBanque

      this.popupBanqueUpdate = true
    },
    updateBanque () {
      const payload = {
        NomAbrege : this.NomAbrege,
        NomBanque : this.NomBanque,
        id: this.params.data._id
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('banque/updateBanque', payload)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.popupBanqueUpdate = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },

    confirmDeleteRecord (data) {
      this.idBanque = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmation de suppression',
        text: `Vous allez supprimé la banque "${data.NomAbrege}"`,
        accept: this.deleteRecord,
        acceptText: 'Supprimer'
      })
    },
    deleteRecord () {
      this.$store.dispatch('banque/removeBanque', this.idBanque)
        .then(()   => { this.showDeleteSuccess() })
        .catch(err => { console.error(err)       })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'primary',
        title: 'Banque Supprimée',
        text: 'La banque selectionné a bien été supprimée'
      })
    },
    getBanqueData () {
      if (this.$store.state.banque.banques.length === 0) {
        this.$store.dispatch('banque/fetchBanques')
          .then(() => {

          })
          .catch(err => {
            console.error(err)
          })
      }
    }
  },

  created () {
    this.getBanqueData()
  }

}

</script>

<style lang="scss">

</style>
